
















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ViewModeType } from '@common-src/model/enum';

@Component
export default class CheckListComponent extends Vue {
    @Prop({ default: 'inspection' })
    checkType: string;
    @Prop()
    viewType: ViewModeType;

    @Prop()
    checkList: Array<{method:string, project:string, type:string, required:boolean, conclusion:boolean, result:boolean}>;
    ViewModeType = ViewModeType;

    get CheckFieldName() {
        switch (this.checkType) {
            case 'inspection':
                return '巡检清单';
            default:
            case 'maintenance':
                return '检查项';
        }
    }

    get CheckColumnName() {
        switch (this.checkType) {
            case 'inspection':
                return '巡检项目';
            default:
            case 'maintenance':
                return '检查项';
        }
    }

    addCheckItem() {
        if (_.isNil(this.checkList)) {
            this.checkList = [];
        }
        if (this.checkList.filter(item => { return item.project === '' && item.method === ''; }).length <= 0) {
            this.checkList.push({ method: '', project: '', type: 'TEXT', required: true, conclusion: false, result: true });
        }
    }
    deleteCheckItem(index) {
        // this.checkList = this.checkList.filter(item => { return item.project !== data.project; });
        this.checkList.splice(index, 1);
    }
}

